import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyA7FcYEJYYf5ntoqQo0sPnX1TgtNFgsUv4",
  authDomain: "scyan-car.firebaseapp.com",
  databaseURL: "https://scyan-car.firebaseio.com",
  projectId: "scyan-car",
  storageBucket: "scyan-car.appspot.com",
  messagingSenderId: "1033893764096",
  appId: "1:1033893764096:web:98674873082ecbcf288ce5"
});

const db = firebaseApp.firestore();

export { db };