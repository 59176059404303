import React from 'react';
import './App.css';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { db } from './firebase';

class App extends React.Component {
  state = {
    car: 0
  }

  componentDidMount() {
    console.log(db)
    db.collection("car")
      .get()
      .then(querySnapshot => {
        this.setState({
          car: querySnapshot.docs[0].data()['lot']
        });
      });
  }

  render() {
    const classes = makeStyles({
      card: {
        minWidth: 275,
      },
      bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
      },
      title: {
        fontSize: 20,
      },
      pos: {
        marginBottom: 12,
      },
      textField: {
        width: 200,
        fontSize: 70
      },
    });


    return (
      <div className="App">
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Where's the car?
            </Typography>
            <TextField
              id="outlined-basic"
              className={classes.textField}
              value={this.state.car}
              onChange={(e) => {
                this.setState({
                  car: e.target.value
                })
                db.collection("car").doc("location").set({
                  lot: e.target.value
                })
                .catch(function (error) {
                  console.error("Error writing document: ", error);
                });
              }}
              label="Outlined"
              margin="normal"
              variant="outlined"
            />
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default App;
